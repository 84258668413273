import { Script } from 'gatsby';

import type { GatsbySSR } from 'gatsby';


export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({ element }) => {
  if (process.env.GATSBY_HUBSPOT_TRACKING_ID) {
    return (
      <>
        {element}
        <Script
          id="hs-script-loader"
          src={`//js.hs-scripts.com/${process.env.GATSBY_HUBSPOT_TRACKING_ID}.js`}
        />
      </>
    );
  }

  return element;
};
